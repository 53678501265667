import { PUBLIC_ENV } from '$env/static/public';
import { init } from '@jill64/sentry-sveltekit-cloudflare/client';

const onError = init(
	'https://8ac7fcbcc77faa241e7ba9df4446adf7@o4508373257224192.ingest.us.sentry.io/4508373325381632',
	{
		sentryOptions: {
			environment: PUBLIC_ENV,
			tracesSampleRate: 1.0
		}
	}
);

export const handleError = onError();
