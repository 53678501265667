import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [3,4,5,6,7];

export const dictionary = {
		"/(auth)": [~9,[2]],
		"/(auth)/register": [~10,[2]],
		"/(auth)/register/invalidated": [11,[2]],
		"/[congregation]/admin/attendance": [~12,[3]],
		"/[congregation]/admin/designations": [~13,[3,4]],
		"/[congregation]/admin/designations/w": [~14,[3,4]],
		"/[congregation]/admin/preaching": [~15,[3,5]],
		"/[congregation]/admin/preaching/cart": [~16,[3,5]],
		"/[congregation]/admin/talks": [~17,[3,6]],
		"/[congregation]/admin/talks/external": [~18,[3,6]],
		"/[congregation]/admin/talks/outlines": [~19,[3,6]],
		"/[congregation]/admin/users": [~20,[3]],
		"/[congregation]/admin/weeks": [~21,[3]],
		"/[congregation]/cleaning": [~22,[3]],
		"/[congregation]/meetings": [~23,[3,7]],
		"/[congregation]/meetings/w": [~24,[3,7]],
		"/[congregation]/preaching/cart": [~26,[3,8]],
		"/[congregation]/preaching/[[tab=preaching]]": [~25,[3,8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';